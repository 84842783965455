export default {
    clearAllState(state) {
        state.token = null
        state.user = null
        state.userUpdate = null
        state.applyForPartnershipStatus = null
        state.signupStatus = null
        state.loginSucess = null
        state.businessDataOnBehalfOf_Email = null
        state.businessDataOnBehalfOf_Name = null
        state.marketingTimeline = null
    },
}