import axios from '../../../axios-auth'

// Actions
import metaUserActions from './metaUserActions.js';
import adAccountActions from './adAccountActions.js';
import adCampaignActions from './adCampaignActions.js';
import instagramActions from './instagram/instagramActions.js';

export default {
  ...metaUserActions,
  ...adAccountActions,
  ...adCampaignActions,
  ...instagramActions,
}
