export function calculateTotalAverageCPC(campaigns, selectedTimePeriod) {
    let totalSpend = 0;
    let totalClicks = 0;

    let daysToSum;
    if (selectedTimePeriod.value === 'day') {
        daysToSum = 1;
    } else if (selectedTimePeriod.value === 'week') {
        daysToSum = 7;
    } else if (selectedTimePeriod.value === 'month') {
        daysToSum = 28;
    } else if (selectedTimePeriod.value === 'maximum') {
        daysToSum = Number.MAX_SAFE_INTEGER;
    }

    campaigns.forEach(campaign => {
        const { data } = campaign;
        const relevantData = daysToSum === Number.MAX_SAFE_INTEGER ? data : data.slice(-daysToSum);

        relevantData.forEach(entry => {
            const spend = parseFloat(entry.spend);
            const actions = entry.actions || [];
            const linkClicks = actions.find(action => action.action_type === 'link_click')?.value || 0;

            if (!isNaN(spend) && spend > 0) {
                totalSpend += spend;
                totalClicks += parseInt(linkClicks, 10);
            }
        });
    });

    const totalAverageCPC = totalClicks > 0 ? totalSpend / totalClicks : 0;

    return totalAverageCPC.toFixed(2);
}

export function calculatePriorAverageCPC(campaigns, selectedTimePeriod) {
    let priorTotalSpend = 0;
    let priorTotalClicks = 0;

    const daysToSum = selectedTimePeriod.sumDays;
    const daysToSkip = daysToSum;

    campaigns.forEach(campaign => {
        const { data } = campaign;

        if (data.length >= daysToSum + daysToSkip) {
            const relevantData = data.slice(-daysToSum - daysToSkip, -daysToSkip);
            relevantData.forEach(entry => {
                const spend = parseFloat(entry.spend);
                const linkClickAction = entry.actions.find(action => action.action_type === 'link_click');
                const linkClicks = linkClickAction ? parseInt(linkClickAction.value, 10) : 0;

                if (linkClicks > 0) {
                    priorTotalSpend += spend;
                    priorTotalClicks += linkClicks;
                }
            });
        }
    });

    const priorAverageCPC = priorTotalClicks > 0 ? priorTotalSpend / priorTotalClicks : 0;
    return priorAverageCPC.toFixed(2);
}

export function calculateTotalAverageCPCTrendPercentage(campaigns, selectedTimePeriod) {
    let startPeriodTotalSpend = 0;
    let endPeriodTotalSpend = 0;
    let startPeriodTotalClicks = 0;
    let endPeriodTotalClicks = 0;

    const periodLength = selectedTimePeriod.sumDays;

    for (const campaign of campaigns) {
        const { data } = campaign;

        if (selectedTimePeriod.days > data.length) {
            return calculateTrendResult(null);
        }

        if (data.length >= 2 * periodLength) {
            const startPeriodData = data.slice(-(2 * periodLength), -periodLength); // Data for the start period
            const endPeriodData = data.slice(-periodLength); // Data for the end period

            startPeriodData.forEach(entry => {
                const spend = parseFloat(entry.spend);
                const clicks = entry.actions.find(action => action.action_type === 'link_click')?.value || 0;
                startPeriodTotalSpend += spend;
                startPeriodTotalClicks += parseInt(clicks, 10);
            });

            endPeriodData.forEach(entry => {
                const spend = parseFloat(entry.spend);
                const clicks = entry.actions.find(action => action.action_type === 'link_click')?.value || 0;
                endPeriodTotalSpend += spend;
                endPeriodTotalClicks += parseInt(clicks, 10);
            });
        }
    };

    const startPeriodAverageCPC = startPeriodTotalClicks > 0 ? startPeriodTotalSpend / startPeriodTotalClicks : 0;
    const endPeriodAverageCPC = endPeriodTotalClicks > 0 ? endPeriodTotalSpend / endPeriodTotalClicks : 0;

    // Calculate the trend percentage
    const trendPercentage = startPeriodAverageCPC !== 0 ? ((endPeriodAverageCPC - startPeriodAverageCPC) / startPeriodAverageCPC) * 100 : 0;

    // Use the helper function to calculate trend result and determine the color
    return calculateTrendResult(trendPercentage);
}


function calculateTrendResult(trendPercentage) {
    let color;
    if (trendPercentage > 0) {
        color = 'text-green-500'; 
    } else if (trendPercentage < 0) {
        color = 'text-red-500';
    } else {
        color = 'text-black';
    }

    if (trendPercentage === null) {
        return {
            value: null,
            color: color
        };
    } else {
        return {
            value: `${trendPercentage > 0 ? '+' : ''}${Math.round(trendPercentage)}%`,
            color: color
        };
    }
}

export function calculateTotalAverageCPCInDays(campaigns) {
    const cpcSumsAndCounts = {};

    campaigns.forEach(campaign => {
        campaign.data.forEach(entry => {
            const { date_stop, spend, actions } = entry;
            if (!cpcSumsAndCounts[date_stop]) {
                cpcSumsAndCounts[date_stop] = { totalSpend: 0, totalClicks: 0 };
            }

            const spendValue = parseFloat(spend);
            const linkClicks = actions.find(action => action.action_type === 'link_click')?.value || 0;
            const linkClicksCount = parseInt(linkClicks, 10);

            if (linkClicksCount > 0) {  // Only consider entries where there are clicks to avoid division by zero
                cpcSumsAndCounts[date_stop].totalSpend += spendValue;
                cpcSumsAndCounts[date_stop].totalClicks += linkClicksCount;
            }
        });
    });

    // Convert the sums into an average CPC array
    const totalAverageCPCArray = Object.keys(cpcSumsAndCounts).map(date => {
        const { totalSpend, totalClicks } = cpcSumsAndCounts[date];
        const averageCPC = totalClicks > 0 ? (totalSpend / totalClicks).toFixed(2) : "0.00";
        return {
            date: date,
            cpc: averageCPC
        };
    });

    totalAverageCPCArray.sort((a, b) => new Date(a.date) - new Date(b.date));

    return totalAverageCPCArray;
}


export function calculateTotalAverageCPCInWeeks(campaigns) {
    const cpcSumsAndCountsByWeek = {};

    // First, ensure all weeks are accounted for, even if their CPC value is 0
    campaigns.forEach(campaign => {
        campaign.dataInWeeks.forEach(weekData => {
            const week = weekData.date_start;
            if (!cpcSumsAndCountsByWeek[week]) {
                cpcSumsAndCountsByWeek[week] = { sum: 0, count: 0, hasNonZeroCPC: false };
            }

            const cpcValue = parseFloat(weekData.cpc);
            if (cpcValue > 0) {
                cpcSumsAndCountsByWeek[week].sum += cpcValue;
                cpcSumsAndCountsByWeek[week].count += 1;
                cpcSumsAndCountsByWeek[week].hasNonZeroCPC = true;
            }
        });
    });

    // Convert the sums and counts into an average CPC array for weeks, ensuring that weeks with only zero CPCs are still included
    const totalAverageCPCInWeeks = Object.keys(cpcSumsAndCountsByWeek).map(week => {
        const { sum, count, hasNonZeroCPC } = cpcSumsAndCountsByWeek[week];
        return {
            week: week,
            cpc: hasNonZeroCPC ? (sum / count).toFixed(2) : "0.00",
        };
    });

    // Sort the array by week
    totalAverageCPCInWeeks.sort((a, b) => {
        const weekA = parseInt(a.week.split('-')[1]);
        const weekB = parseInt(b.week.split('-')[1]);
        const yearA = parseInt(a.week.split('-')[0]);
        const yearB = parseInt(b.week.split('-')[0]);

        if (yearA !== yearB) {
            return yearA - yearB;
        }
        return weekA - weekB;
    });

    return totalAverageCPCInWeeks;
}


export function calculateTotalAverageCPCInMonths(campaigns) {
    const cpcSumsAndCountsByMonth = {};

    campaigns.forEach(campaign => {
        campaign.dataInMonths.forEach(monthData => {
            const month = monthData.date_start;
            if (!cpcSumsAndCountsByMonth[month]) {
                cpcSumsAndCountsByMonth[month] = { sum: 0, count: 0, hasNonZeroCPC: false };
            }

            const cpcValue = parseFloat(monthData.cpc);
            if (cpcValue > 0) {
                cpcSumsAndCountsByMonth[month].sum += cpcValue;
                cpcSumsAndCountsByMonth[month].count += 1;
                cpcSumsAndCountsByMonth[month].hasNonZeroCPC = true;
            }
        });
    });

    const totalAverageCPCInMonths = Object.keys(cpcSumsAndCountsByMonth).map(month => {
        const { sum, count, hasNonZeroCPC } = cpcSumsAndCountsByMonth[month];
        return {
            month: month,
            cpc: hasNonZeroCPC ? (sum / count).toFixed(2) : "0.00",
        };
    });

    totalAverageCPCInMonths.sort((a, b) => new Date(a.month + "-01") - new Date(b.month + "-01"));

    return totalAverageCPCInMonths;
}



export function calculateTotalAverageCPCInYears(campaigns) {
    const cpcSumsAndCountsByYear = {};

    campaigns.forEach(campaign => {
        campaign.dataInYears.forEach(yearData => {
            const year = yearData.date_start;
            if (!cpcSumsAndCountsByYear[year]) {
                // Initialize with hasNonZeroCPC to track if any non-zero CPC values are present
                cpcSumsAndCountsByYear[year] = { sum: 0, count: 0, hasNonZeroCPC: false };
            }

            const cpcValue = parseFloat(yearData.cpc);
            if (cpcValue > 0) {
                cpcSumsAndCountsByYear[year].sum += cpcValue;
                cpcSumsAndCountsByYear[year].count += 1;
                cpcSumsAndCountsByYear[year].hasNonZeroCPC = true;
            }
        });
    });

    const totalAverageCPCInYears = Object.keys(cpcSumsAndCountsByYear).map(year => {
        const { sum, count, hasNonZeroCPC } = cpcSumsAndCountsByYear[year];
        return {
            year: year,
            cpc: hasNonZeroCPC ? (sum / count).toFixed(2) : "0.00",
        };
    });

    totalAverageCPCInYears.sort((a, b) => a.year.localeCompare(b.year));

    return totalAverageCPCInYears;
}
