import axios from '../../../axios-auth'

import { toRaw } from "vue";

const routePath = '/meta';

export default {
    getAdAccountCampaigns({ commit, getters, dispatch }, data) {
        const access_token = getters.getUserMetaCredentials.accessToken

        axios.get(`${routePath}/getCampaigns?ad_account_id=${data.lastSelectedAdAccountID}&access_token=${access_token}`)
            .then((res) => {

                commit('storeFacebookAdCampaigns', res.data)
                commit('resetAdCampaignsObjectDetailed')

                const insightPromises = res.data.data.map(element => {
                    return dispatch('getMetaCampaignInsight', {
                        campaignId: element.id,
                        campaignName: element.name,
                        insightParams: data.insightParams,
                        selectedTimePeriod: data.selectedTimePeriod
                    });
                });

                
                Promise.all(insightPromises)
                .then(() => {
                        // The insightPromeise
                        const detailedAdCampaigns = toRaw(getters.getAdCampaignsDetailed);
                        commit('calculateAndStoreTotalSpend', detailedAdCampaigns);
                        commit('updateAdCampaignClickMetrics', detailedAdCampaigns);
                        commit('updateAdCampaignCPCMetrics', detailedAdCampaigns);
                    })
                    .catch(error => {
                        console.error('An error occurred while fetching campaign insights:', error);
                    });
            })
            .catch((error) => {
                console.log('action error', error);
            });
    },

    getMetaCampaignInsight({ commit, getters }, data) {
        const { time_range, time_increment, fields, date_preset } = data.insightParams;
        const selectedTimePeriod = data.selectedTimePeriod

        // For debugging
        // console.log('getMetaCampaignInsight data : ', data);
        // console.log('time_range: ', time_range);
        // console.log('time_increment: ', time_increment);
        // console.log('date_preset: ', date_preset);
        // console.log('getMetaCampaignInsight action data: ', data.selectedTimePeriod);

        const adCampaignId = data.campaignId
        const adCampaignName = data.campaignName
        const access_token = getters.getUserMetaCredentials.accessToken

        let params = {
            selectedTimePeriod: JSON.stringify(selectedTimePeriod), // Stringify the object
            access_token: access_token,
            time_increment: time_increment,
            campaign_name: adCampaignName,
            fields: `${fields}`,
        };

        if (date_preset) {
            params.date_preset = date_preset;
        } else if (time_range) {
            params.time_range = time_range;
        }

        return axios.get(`${routePath}/getCampaign/${adCampaignId}`, { params })
            .then((res) => {
                commit('addCampaignToAdCampaignsObjectDetailed', res.data)
            })
            .catch((error) => {
                console.log('getMetaCampaignInsight action error: ', error.message);
            });
    }
}