<script setup>
import AppBreadcrumb from './AppBreadcrumb.vue';
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';

// Router
import { useRoute } from 'vue-router';
const route = useRoute();

// Components
import PaymentModal from '@/views/payment/PaymentModal.vue';

// Store
import { useStore } from 'vuex';
const store = useStore();

// Computed
const unreadReceivedMessages = computed(() => {
    return store.state.communication.messages.receivedMessagesUnread.length;
});
const subscriptionPlanReady = computed(() => {
    return subscriptionPlan.value !== "loading...";
});
const subscriptionPlan = computed(() => {
    if (store.state.user && store.state.user.subscriptionPlan !== null) {
        return store.state.user.subscriptionPlan.name;
    } else {
        return 'Loading...';
    }
});
const profileImage = computed(() => {
    return store.state.user && store.state.user.profile && store.state.user.profile.image && store.state.user.profile.image.url
        ? store.state.user.profile.image.url
        : "/images/icons/avatar.png";
});

const { onMenuToggle, onProfileSidebarToggle, onConfigSidebarToggle } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);

onMounted(() => {
    bindOutsideClickListener();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};

const showProfileSidebar = () => {
    onProfileSidebarToggle();
};

const onConfigButtonClick = () => {
    onConfigSidebarToggle();
};

const avatarStyle = ref({
    backgroundColor: 'var(--surface-ground)', // Default background color
    color: 'var(--text-color)', // Default icon color
});

// Function to change style on click
const setAvatarActiveStyle = () => {
    avatarStyle.value = {
        backgroundColor: 'var(--primary-blue)', // Your blue color variable
        color: '#3b82f6', // Icon color when active
    };
};

watch(route, (newValue) => {
    if (newValue.path !== '/messages/inbox') {
        avatarStyle.value = {
            backgroundColor: 'var(--surface-ground)',
            color: 'var(--text-color)',
        };
    }
});

// Subscription related
const isSubscriptionModal = ref(false);
const openModal = () => {
    isSubscriptionModal.value = true;
}
const closeModal = () => {
    isSubscriptionModal.value = false;
}

</script>

<template>
    <div class="layout-topbar">
        <div class="topbar-start">
            <Button type="button" class="topbar-menubutton p-link p-trigger" @click="onMenuToggle">
                <i class="pi pi-bars"></i>
            </Button>

            <AppBreadcrumb class="topbar-breadcrumb"></AppBreadcrumb>
        </div>

        <div class="topbar-end">
            <ul class="topbar-menu">
                <!-- <li class="topbar-search">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <InputText type="text" placeholder="Search" class="w-12rem sm:w-full" />
                    </span>
                </li> -->
                <!-- <li class="ml-3">
                    <Button icon="pi pi-cog" text rounded severity="secondary" @click="onConfigButtonClick"></Button>
                </li> -->
                
                <!-- TODO: Reimplement to enable payment -->
                <!-- <li class="mr-2">
                    <Button @click="openModal" :label="subscriptionPlan" severity="secondary" text />
                </li> -->
                
                <!-- TODO: taken out for now -->
                <!-- <li>
                    <router-link to="/messages/inbox">
                        <Avatar v-if="unreadReceivedMessages <= 0" class="message-icon" size="large" :style="avatarStyle"
                            @click="setAvatarActiveStyle">
                            <i class="pi pi-comment"></i>
                        </Avatar>
                        <Avatar v-else class="message-icon" v-badge="unreadReceivedMessages" size="large"
                            :style="avatarStyle" @click="setAvatarActiveStyle">
                            <i class="pi pi-comment"></i>
                        </Avatar>
                    </router-link>
                </li> -->
                <li class="topbar-profile">
                    <Button type="button" class="p-link" @click="showProfileSidebar">
                        <img :src="profileImage" alt="Profile" /></Button>
                </li>
            </ul>
        </div>
    </div>

    <PaymentModal v-if="subscriptionPlanReady" :userSubscriptionPlan="subscriptionPlan" :visible="isSubscriptionModal"
        @update:visible="closeModal"></PaymentModal>
</template>

<style lang="scss" scoped>
.message-icon {
    font-size: 10rem;
    /* Adjust as needed */
    // Replace with the actual size of your topbar-profile icon
    width: 30px;
    height: 30px;

    // Add any additional styling here as needed
    .pi.pi-comment {
        // Increase the font size of the icon inside the avatar
        font-size: 1.5rem;
        /* Adjust as needed */
    }
}

.topbar-profile {
    // Ensure this matches the .message-icon size
    width: 40px;
    height: 40px;
}
</style>
