<script setup>
import { ref } from 'vue';
import AppMenuItem from './AppMenuItem.vue';

defineProps({
    model: {
        type: Array,
        default: null
    }
});

// Subscription related
const isModalVisible = ref(false);

</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <AppMenuItem :item="item" root :index="i" />

            <li class="menu-separator"></li>

            <div class="absolute bottom-0 flex w-full align-items-center justify-content-center mb-3">
                <Button @click="isModalVisible = true" text class="w-full" label="Support" />
            </div>
        </template>
    </ul>

    <Dialog v-model:visible="isModalVisible" modal header="Support" closeOnEscape dismissableMask
        :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <p>
            MinMarketing.dk er en forholdsvis ny platform, og derfor kan der opstå og opleves forskellige fejl<br><br>
            For at sikre den bedst mulige brugeroplevelse, kan vores support kontaktes direkte. <br><br>

            <!-- <b>Discord:</b> <br /> <a href="https://discord.gg/6pUwwub775">https://discord.gg/6pUwwub775</a> <br><br> -->

            <b>Tlf:</b> <br /> +45 30 11 93 22 <br><br>

            <b>Email:</b> <br /> Kontakt@minmarketing.dk
        </p>
    </Dialog>
</template>
