<script setup>
import { useLocaleMessages } from '@/utils/locales/useLocaleMessages';

// Load locales
useLocaleMessages({
  da: () => import('@/locales/da/landingPage.json'),
  en: () => import('@/locales/en/landingPage.json'),
});

// Router
import { useRouter } from 'vue-router';
const router = useRouter();

</script>

<template>
    <div class="text-900 font-bold text-6xl mb-4 text-center">{{ $t('pricing-title') }}</div>
    <div class="text-700 text-xl mb-6 text-center line-height-3">{{ $t('pricing-subtitle') }}</div>

    <div class="grid">
        <!-- Starter Plan -->
        <div class="col-12 lg:col-4">
            <div class="p-3 h-full">
                <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                    <div class="text-900 font-medium text-xl mb-2">{{ $t('starter-plan-title') }}</div>
                    <div class="text-600 h-3rem">{{ $t('starter-plan-description') }}</div>
                    <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                    <div class="flex align-items-center">
                        <span class="font-bold text-2xl text-900">{{ $t('starter-plan-price') }}</span>
                    </div>
                    <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                    <ul class="list-none p-0 m-0 flex-grow-1">
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('meta-ads-tracking') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('content-calendar') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('marketing-timeline') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('active-business-partners-3') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('instagram-tracking') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-times text-red-500 mr-2"></i>
                            <span>{{ $t('ad-storage') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-times text-red-500 mr-2"></i>
                            <span>{{ $t('learning-section') }}</span>
                        </li>
                    </ul>
                    <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                    <Button @click="router.push('/auth/signin')" :label="$t('choose-plan')" class="p-3 w-full p-button-outlined"
                        icon="pi pi-arrow-right" iconPos="right"></Button>
                </div>
            </div>
        </div>

        <!-- Professional Plan -->
        <div class="col-12 lg:col-4">
            <div class="p-3 h-full">
                <div class="shadow-2 p-3 flex flex-column surface-card" style="border-radius: 6px">
                    <div class="text-900 font-medium text-xl mb-2">{{ $t('professional-plan-title') }}</div>
                    <div class="text-600 h-3rem">{{ $t('professional-plan-description') }}</div>
                    <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                    <div class="flex align-items-center">
                        <span class="font-bold text-2xl text-900">{{ $t('professional-plan-price') }}</span>
                        <span class="ml-2 font-medium text-600">{{ $t('vat') }}</span>
                    </div>
                    <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                    <ul class="list-none p-0 m-0 flex-grow-1">
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('advanced-meta-ads-tracking') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('content-calendar') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('marketing-timeline') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('active-business-partners-10') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('instagram-tracking-ai') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('ad-storage') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('ai-generator') }}</span>
                        </li>
                    </ul>
                    <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
                    <Button @click="router.push('/auth/signin')" :label="$t('choose-plan')" class="p-3 w-full p-button-outlined"
                        icon="pi pi-arrow-right" iconPos="right"></Button>
                </div>
            </div>
        </div>

        <!-- Collaboration Plan -->
        <div class="col-12 lg:col-4">
            <div class="p-3 h-full">
                <div class="shadow-2 p-3 flex flex-column surface-card" style="border-radius: 6px">
                    <div class="text-900 font-medium text-xl mb-2">{{ $t('collaboration-plan-title') }}</div>
                    <div class="text-600 h-3rem">{{ $t('collaboration-plan-description') }}</div>
                    <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                    <div class="flex align-items-center">
                        <span class="font-bold text-2xl text-900">{{ $t('collaboration-plan-price') }}</span>
                    </div>
                    <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
                    <ul class="list-none p-0 m-0 flex-grow-1">
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('advanced-meta-ads-tracking') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('content-calendar') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('marketing-timeline') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('active-business-partners-10') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('instagram-tracking-ai') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('ad-storage') }}</span>
                        </li>
                        <li class="flex align-items-center mb-3">
                            <i class="pi pi-check text-green-500 mr-2"></i>
                            <span>{{ $t('ai-generator') }}</span>
                        </li>
                    </ul>
                    <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
                    <Button @click="router.push('/auth/signin')" :label="$t('choose-plan')" class="p-3 w-full p-button-outlined"
                        icon="pi pi-arrow-right" iconPos="right"></Button>
                </div>
            </div>
        </div>
    </div>
</template>
