export default {
    addBusinessPartnerRequest(state, email) {
        state.user.business_partner_requests.push({
            email: email.toLowerCase(),
            status: 'pending'
        });
    },
    removeBusinessPartnerRequest(state, email) {
        const index = state.user.business_partner_requests.findIndex((request) => {
            return request.email === email;
          });
        if (index !== -1) {
            state.user.business_partner_requests.splice(index, 1);
        }
    },


    addBusinessPartner(state, business_partner) {
        state.user.business_partners.push(business_partner);
    },

    removeBusinessPartner(state, email) {
        const index = state.user.business_partners.findIndex((partner) => {
            return partner.email === email;
          });
          console.log(index)
        if (index !== -1) {
            state.user.business_partners.splice(index, 1);
        }
        state.businessDataOnBehalfOf_Email = null;
        state.businessDataOnBehalfOf_Name = null;
    }
}
