export function calculateTotalSpend(campaigns, selectedTimePeriod) {
    let totalSpend = 0;

    if (selectedTimePeriod.value === 'maximum') {
        campaigns.forEach(campaign => {
            const { data } = campaign;
            const sumForCampaign = data.reduce((acc, curr) => acc + parseFloat(curr.spend), 0);
            totalSpend += sumForCampaign;
        });
    } else {
        campaigns.forEach(campaign => {
            const { data } = campaign;
            const daysToSum = selectedTimePeriod.sumDays; 
            const relevantData = data.slice(-daysToSum);
            const sumForCampaign = relevantData.reduce((acc, curr) => acc + parseFloat(curr.spend), 0);
            totalSpend += sumForCampaign;
        });
    }

    return totalSpend;
}

export function calculatePriorTotalSpend(campaigns, selectedTimePeriod) {
    let priorTotalSpend = 0;

    if (selectedTimePeriod.value === 'maximum') {
        priorTotalSpend = 0;
    } else {
        campaigns.forEach(campaign => {
            const { data } = campaign;
            let daysToSum = 0;
            let daysToSkip = 0;

            if (data.length === 7) {
                daysToSum = 1; // Prior day only
                daysToSkip = 1;
            } else if (data.length === 14) {
                daysToSum = 7; // Prior 7 days
                daysToSkip = 7;
            } else if (data.length === 56) {
                daysToSum = 28; // Prior 28 days
                daysToSkip = 28;
            }

            // Skip the most recent period and then take the daysToSum for calculating prior total spend
            const relevantData = data.slice(-daysToSum - daysToSkip, -daysToSkip);
            const sumForPeriod = relevantData.reduce((acc, curr) => acc + parseFloat(curr.spend), 0);
            priorTotalSpend += sumForPeriod;
        });
    }

    return priorTotalSpend;
}

export function calculateTotalSpendByDate(campaigns) {
    let spendByDate = {};

    campaigns.forEach(campaign => {
        const { data } = campaign;

        data.forEach(entry => {
            // Check if the date already exists in the aggregation object
            if (spendByDate[entry.date_start]) {
                // If it does, add the current spend to the existing amount
                spendByDate[entry.date_start] += parseFloat(entry.spend);
            } else {
                // If it doesn't, initialize the date with the current spend
                spendByDate[entry.date_start] = parseFloat(entry.spend);
            }
        });
    });

    // Transform the aggregation object into an array of objects
    let totalSpendArray = Object.keys(spendByDate).map(date => {
        return { date: date, spend: spendByDate[date].toFixed(2) }; // Round to two decimal places for consistency
    });

    totalSpendArray.sort((a, b) => new Date(a.date) - new Date(b.date));

    return totalSpendArray;
}

export function calculateTotalSpendInWeeks(campaigns) {
    let spendByWeek = {};

    campaigns.forEach(campaign => {
        const { dataInWeeks } = campaign;

        dataInWeeks.forEach(entry => {
            if (spendByWeek[entry.date_start]) {
                spendByWeek[entry.date_start] += parseFloat(entry.spend);
            } else {
                spendByWeek[entry.date_start] = parseFloat(entry.spend);
            }
        });
    });

    let totalSpendInWeeks = Object.keys(spendByWeek).map(week => {
        return { week: week, spend: spendByWeek[week].toFixed(2) };
    });

    // Assuming the week format is "YYYY-WW" and can be sorted lexically
    totalSpendInWeeks.sort((a, b) => a.week.localeCompare(b.week));

    return totalSpendInWeeks;
}

export function calculateTotalSpendInMonths(campaigns) {
    let spendByMonth = {};

    campaigns.forEach(campaign => {
        const { dataInMonths } = campaign;

        dataInMonths.forEach(entry => {
            if (spendByMonth[entry.date_start]) {
                spendByMonth[entry.date_start] += parseFloat(entry.spend);
            } else {
                spendByMonth[entry.date_start] = parseFloat(entry.spend);
            }
        });
    });

    let totalSpendInMonths = Object.keys(spendByMonth).map(month => {
        return { month: month, spend: spendByMonth[month].toFixed(2) };
    });

    // Assuming the month format is "YYYY-MM" and can be sorted lexically
    totalSpendInMonths.sort((a, b) => a.month.localeCompare(b.month));

    return totalSpendInMonths;
}
export function calculateTotalSpendInYears(campaigns) {
    let spendByYear = {};

    campaigns.forEach(campaign => {
        const { dataInYears } = campaign;

        dataInYears.forEach(entry => {
            if (spendByYear[entry.date_start]) {
                spendByYear[entry.date_start] += parseFloat(entry.spend);
            } else {
                spendByYear[entry.date_start] = parseFloat(entry.spend);
            }
        });
    });

    let totalSpendInYears = Object.keys(spendByYear).map(year => {
        return { year: year, spend: spendByYear[year].toFixed(2) };
    });

    // Sort years numerically
    totalSpendInYears.sort((a, b) => parseInt(a.year) - parseInt(b.year));

    return totalSpendInYears;
}