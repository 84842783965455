export default {
    storeMessages(state, messages) {
        if (messages) {
            state.communication.messages.sentMessages = messages.sentMessages;
            state.communication.messages.receivedMessages = messages.receivedMessages;

            state.communication.messages.receivedMessagesUnread = messages.receivedMessages.filter(message => {
                return message.status === 'unread';
            });
        }
    },

    storeSentMessage(state, message) {
        state.communication.messages.sentMessages.push(message);
    },

    storeReceivedMessage(state, message) {
        state.communication.messages.receivedMessages.push(message);
        state.communication.messages.receivedMessagesUnread.push(message);
    },

    removeMessageFromUnread(state, messageUUID) {
        state.communication.messages.receivedMessagesUnread = state.communication.messages.receivedMessagesUnread.filter(m => m.uuid !== messageUUID);
    },

    markMessageAsRead(state, messageUUID) {

        // First try to find the message in sentMessages
        let found = false;
        for (let message of state.communication.messages.sentMessages) {
            if (message.uuid === messageUUID) {
                message.status = 'read';
                found = true;
                break;
            }
        }

        // If not found in sentMessages, try to find it in receivedMessages
        if (!found) {
            for (let message of state.communication.messages.receivedMessages) {
                if (message.uuid === messageUUID) {
                    message.status = 'read';
                    break;
                }
            }
        }
    },

    deleteMessage(state, messageUUID) {
        let index = state.communication.messages.sentMessages.findIndex(m => m.uuid === messageUUID);
        if (index !== -1) {
            state.communication.messages.sentMessages.splice(index, 1);
        } else {
            // If not found in sentMessages, try to remove it from receivedMessages
            index = state.communication.messages.receivedMessages.findIndex(m => m.uuid === messageUUID);
            if (index !== -1) {
                state.communication.messages.receivedMessages.splice(index, 1);
            }
        }
    },

    addCommentToMessage(state, { messageUUID, comment }) {
        // First try to find the message in sentMessages
        let found = false;
        for (let message of state.communication.messages.sentMessages) {
            if (message.uuid === messageUUID) {
                message.comments.push(comment);
                found = true;
                break;
            }
        }

        // If not found in sentMessages, try to find it in receivedMessages
        if (!found) {
            for (let message of state.communication.messages.receivedMessages) {
                if (message.uuid === messageUUID) {
                    message.comments.push(comment);
                    break;
                }
            }
        }
    }
}