import axios from '../../axios-auth'
import router from '../../../router'

const routePath = '/auth';

export default {

    /**
     * @description Standard Signup
    **/
    async signup({ }, authData) {

        console.log("authData from dispatcher: ", authData)

        return axios.post(`${routePath}/signup`, {
            userType: authData.userType,
            name: authData.name,
            companyName: authData.companyName,
            email: authData.email,
            password: authData.password,
            businessPartnerEmail: authData.businessPartnerEmail,
            returnSecureToken: true
        })
            .then(() => {
                return { status: "success", message: "Oprettelsen gik godt! Prøv dit nye login" };
            })
            .catch(error => {
                let errorMessage = "Noget gik galt under oprettelse prøv igen om lidt";
                if (error.response && error.response.data.error === "User already exists") {
                    errorMessage = "Bruger er allerede oprettet i systemet";
                }
                throw new Error(errorMessage);
            })
    },


    signupStatusReset({ commit }) {
        commit('signupStatusReset')
    },

    facebook_login({ commit, dispatch }, authData) {
        axios.post(`${routePath}/facebookLogin`, {
            fullName: authData.fullName,
            username: authData.username,
            email: authData.email.toLowerCase(),
            profile_image: authData.profile_image,
            facebook_id: authData.facebook_id,
            returnSecureToken: true
        })
            .then(res => {
                commit('loginSucess')
                const now = new Date()
                const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000)
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('expirationDate', expirationDate)
                commit('authUser', {
                    token: res.data.token,
                })
                dispatch('setLogoutTimer', res.data.expiresIn);
                commit('storeUser', res.data.user);
                router.replace('/');
            })
            .catch(error => {
                console.log("state error" + error)
            })
    },




    /**
     * @description Signin User
     */
    signin({ commit, dispatch }, authData) {
        return new Promise((resolve, reject) => {
            axios.post(`${routePath}/signin`, {
                email: authData.email.toLowerCase(),
                password: authData.password,
                returnSecureToken: true
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            },
                {
                    withCredentials: true
                })
                .then(res => {
                    console.log("Login SUCCESS: Login success")
                    const now = new Date()
                    const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000)
                    localStorage.setItem('token', res.data.token)
                    localStorage.setItem('refreshToken', res.data.refreshToken)
                    localStorage.setItem('expirationDate', expirationDate)
                    dispatch("getAuthUser");
                    commit('authUser', {
                        token: res.data.token,
                    })
                    dispatch('setLogoutTimer', res.data.expiresIn);
                    resolve();
                })
                .catch(error => {
                    console.log("Login ERROR: Login error")
                    reject(error.response);
                })
        })
    },

    refreshAccessToken({ }, { refreshToken: refreshToken }) {
        return new Promise((resolve, reject) => {
            axios.post(`${routePath}/refreshAccessToken`, { refreshToken: refreshToken })
                .then(res => {
                    if (res.status === 201) {
                        const now = new Date()
                        const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000)
                        localStorage.setItem('token', res.data.token);
                        localStorage.setItem('refreshToken', res.data.refreshToken)
                        localStorage.setItem('expirationDate', expirationDate)
                        return resolve(res);
                    }
                })
                .catch(error => {
                    console.log("Login ERROR: Login error")
                    reject(error.response);
                })
        })
    },


    /**
     * @description Signin using using google
    **/
    signin_google({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            axios.post(`${routePath}/google/signin`, {
                data,
                returnSecureToken: true
            })
                .then(res => {
                    const now = new Date()
                    const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000)
                    localStorage.setItem('token', res.data.token)
                    localStorage.setItem('expirationDate', expirationDate)
                    dispatch("getAuthUser");
                    commit('authUser', {
                        token: res.data.token,
                    })
                    dispatch('setLogoutTimer', res.data.expiresIn);
                    resolve();
                })
                .catch(error => {
                    console.log("Login ERROR: Login error")
                    reject(error.response);
                })
        })
    },



    loginReset({ commit }) {
        commit('loginReset')
    },

    getAuthUser({ commit, dispatch, state }) {
        if (!state.authUserPromise) {
            state.authUserPromise = new Promise((resolve, reject) => {
                const token = localStorage.getItem('token');

                if (!token || new Date() >= new Date(localStorage.getItem('expirationDate'))) {
                    reject('Token is expired');
                    return;
                }

                commit('authUser', { token });

                dispatch('fetchUser')
                    .then(response => {
                        resolve(response); // Resolve on successful fetchUser
                    })
                    .catch(error => {
                        reject(error); // Reject on error in fetchUser
                    });
            });
        }

        return state.authUserPromise;
    },


    signout({ state, commit }) {
        axios.post(`${routePath}/signout`, { userUUID: state.user.uuid })
            .then((res) => {
                console.log('Signout success', res);
                console.log('Going to sign out users now');
                commit('clearSession')
                router.replace(`/`).then(() => {
                    window.location.reload();
                });
            })
            .catch((error) => {
                console.log('Signout error', error);
            });
    },
}