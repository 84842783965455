// Service
import { LocalStorageService } from "@/service/localStorage/LocalStorageService";

// Initialize service
const localStorageService = new LocalStorageService();

interface FacebookAuthResponse {
  accessToken: string;
  data_access_expiration_time: number;
  expiresIn: number;
  graphDomain: string;
  signedRequest: string;
  userID: string;
}

interface FacebookUserAuth {
  platformCredentials: {
    meta: {
      accessToken: string;
      dataAccessExpirationTime: number;
      expiresIn: number;
      graphDomain: string;
      signedRequest: string;
      userID: string;
      pages: [{
        id: String | null,           // Facebook Page ID
        name: String | null,         // Facebook Page Name
        category: String | null,     // Page Category
        instagram: {
          id: String | null,        // Instagram Business Account ID (retrieved later)
          username: String| null    // Instagram Username (retrieved later)
        }
      }]
    };
  };
}

interface MetaConnectionResult {
  success: boolean;
  message: string;
}

export class MetaService {
  triggerMetaConnection(store: any): Promise<MetaConnectionResult> {
    console.log("Triggering Facebook login from MetaService.ts ...");

    console.log("TESTING...");
    // console.log("Facebook App ID:", import.meta.env.VITE_FACEBOOK_APP_ID);
    console.log("Meta Config ID:", import.meta.env.VITE_META_CONFIG_ID);

    return new Promise((resolve, reject) => {
      if (!window.FB) {
        return resolve({
          success: false,
          message: "Facebook SDK not loaded.",
        });
      }

      FB.login(function (response: any) {
          console.log("Facebook login response:", response);

          if (response.status === "connected") {
            const authResponse: FacebookAuthResponse = response.authResponse;
            console.log("Facebook auth response:", authResponse);
            const facebookUserAuth: FacebookUserAuth = {
              platformCredentials: {
                meta: {
                  accessToken: authResponse.accessToken,
                  dataAccessExpirationTime:
                    authResponse.data_access_expiration_time,
                  expiresIn: authResponse.expiresIn,
                  graphDomain: authResponse.graphDomain,
                  signedRequest: authResponse.signedRequest,
                  userID: authResponse.userID,
                  pages: [{
                    id: null,
                    name: null,
                    category: null,
                    instagram: {
                      id: null,
                      username: null
                    }
                  }],
                },
              },
            };

            store
              .dispatch("initMetaUser", facebookUserAuth)
              .then((res: any) => {

                console.log("THIS IS THE FULL RESPONSE")
                console.log(res)

                const pages = res.data.data.platformCredentials.meta.pages;
                const pageWithInstagram = pages.find((page: any) => page.instagram && page.instagram.id);

                if (pageWithInstagram) {
                  localStorageService.storeItemAsJSON("meta-selectedPage", pageWithInstagram);
                }

                resolve({ success: true, message: "You have successfully connected your Facebook account.",});
              })
              .catch((error: any) => {
                console.error("Error handling Facebook data: ", error);
                resolve({
                  success: false,
                  message: "Failed to connect your Facebook account.",
                });
              });
          } else {
            resolve({
              success: false,
              message: "User cancelled login or did not fully authorize.",
            });
          }
        },
        {
          scope: "public_profile,email",
          config_id: import.meta.env.VITE_META_CONFIG_ID,
        }
      );
    });
  }
  

  // TODO just storing
  //          scope: "public_profile,email,instagram_basic,instagram_manage_insights,pages_read_engagement,pages_show_list,ads_read",


  getMetaPageSelection(): any {
    const storedPage = localStorageService.getItemAsJSON("meta-selectedPage");
    if (storedPage && storedPage.instagram && storedPage.instagram.id) {
      return storedPage;
    }
    return null;
  }
}
