// Helper function to calculate trend percentage and determine color
function calculateTrendResult(trendPercentage) {
    let color;
    if (trendPercentage > 0) {
        color = 'text-green-500';  // Positive trend, green color
    } else if (trendPercentage < 0) {
        color = 'text-red-500';    // Negative trend, red color
    } else {
        color = 'text-black';      // No change, neutral color
    }

    if (trendPercentage === null) {
        return {
            value: null,
            color: color
        };
    } else {
        return {
            value: `${trendPercentage > 0 ? '+' : ''}${Math.round(trendPercentage)}%`,
            color: color
        };
    }
}

export function calculateTotalClicksOnAd(campaigns, selectedTimePeriod) {
    let totalClicks = 0;

    campaigns.forEach(campaign => {
        const { data } = campaign;
        let daysToSum = selectedTimePeriod.sumDays;

        if (selectedTimePeriod.value === 'maximum') {
            daysToSum = data.length;  // Consider all available data
        }

        const relevantData = data.slice(-daysToSum);
        relevantData.forEach(entry => {
            const linkClickAction = entry.actions.find(action => action.action_type === 'link_click');
            totalClicks += linkClickAction ? parseInt(linkClickAction.value, 10) : 0;
        });
    });

    return totalClicks;
}

export function calculatePriorTotalClicksOnAd(campaigns, selectedTimePeriod) {
    let priorTotalClicks = 0;

    const { sumDays } = selectedTimePeriod;
    let daysToSkip = sumDays;

    campaigns.forEach(campaign => {
        const { data } = campaign;

        const relevantData = data.slice(-sumDays - daysToSkip, -daysToSkip);
        relevantData.forEach(entry => {
            const linkClickAction = entry.actions.find(action => action.action_type === 'link_click');
            if (linkClickAction) {
                priorTotalClicks += parseInt(linkClickAction.value, 10);
            }
        });
    });

    return priorTotalClicks;
}

export function calculateTotalClicksOnAdTrendPercentage(campaigns, selectedTimePeriod) {
    let startPeriodTotalClicks = 0;
    let endPeriodTotalClicks = 0;

    const periodLength = selectedTimePeriod.sumDays;
    const dataSliceSize = periodLength * 2;

    for (const campaign of campaigns) {
        const { data } = campaign;

        if (selectedTimePeriod.days > data.length) {
            return calculateTrendResult(null);  // Skip calculation and return null
        }

        if (data.length >= dataSliceSize) {
            const startPeriodData = data.slice(-dataSliceSize, -periodLength); 
            const endPeriodData = data.slice(-periodLength); // Data for the end period

            startPeriodData.forEach(entry => {
                const linkClickAction = entry.actions.find(action => action.action_type === 'link_click');
                startPeriodTotalClicks += linkClickAction ? parseInt(linkClickAction.value, 10) : 0;
            });

            endPeriodData.forEach(entry => {
                const linkClickAction = entry.actions.find(action => action.action_type === 'link_click');
                endPeriodTotalClicks += linkClickAction ? parseInt(linkClickAction.value, 10) : 0;
            });
        }
    }

    const trendPercentage = startPeriodTotalClicks !== 0
        ? ((endPeriodTotalClicks - startPeriodTotalClicks) / startPeriodTotalClicks) * 100
        : 0;

    return calculateTrendResult(trendPercentage);
}

export function calculateTotalClicksOnAdByDate(campaigns) {
    let clicksOnAdByDate = {};

    campaigns.forEach(campaign => {
        const { data } = campaign;

        data.forEach(entry => {
            const linkClicks = entry.actions.find(action => action.action_type === 'link_click')?.value || 0;
            const date = entry.date_start;

            if (clicksOnAdByDate[date]) {
                clicksOnAdByDate[date] += parseInt(linkClicks, 10);
            } else {
                clicksOnAdByDate[date] = parseInt(linkClicks, 10);
            }
        });
    });

    let totalClicksOnAdArray = Object.keys(clicksOnAdByDate).map(date => {
        return { date: date, clicksOnAd: clicksOnAdByDate[date] };
    });

    totalClicksOnAdArray.sort((a, b) => new Date(a.date) - new Date(b.date));

    return totalClicksOnAdArray;
}

export function calculateTotalClicksOnAdInWeeks(campaigns) {
    let clicksByWeek = {};

    campaigns.forEach(campaign => {
        campaign.dataInWeeks.forEach(entry => {
            let week = entry.date_start;
            let linkClickAction = entry.actions.find(action => action.action_type === "link_click");
            let clicksOnAd = linkClickAction ? parseInt(linkClickAction.value, 10) : 0;

            if (clicksByWeek[week]) {
                clicksByWeek[week] += clicksOnAd;
            } else {
                clicksByWeek[week] = clicksOnAd;
            }
        });
    });

    let totalClicksOnAdInWeeks = Object.keys(clicksByWeek).map(week => ({
        week: week,
        clicksOnAd: clicksByWeek[week]
    }));

    totalClicksOnAdInWeeks.sort((a, b) => a.week.localeCompare(b.week));

    return totalClicksOnAdInWeeks;
}

export function calculateTotalClicksOnAdInMonths(campaigns) {
    let clicksByMonth = {};

    campaigns.forEach(campaign => {
        campaign.dataInMonths.forEach(entry => {
            const month = entry.date_start; // Assuming date_start represents the month in 'YYYY-MM' format
            const linkClickAction = entry.actions.find(action => action.action_type === "link_click");
            const linkClicks = linkClickAction ? parseInt(linkClickAction.value, 10) : 0;

            if (clicksByMonth[month]) {
                clicksByMonth[month] += linkClicks;
            } else {
                clicksByMonth[month] = linkClicks;
            }
        });
    });

    let totalClicksOnAdInMonths = Object.keys(clicksByMonth).map(month => ({
        month: month,
        clicksOnAd: clicksByMonth[month]
    }));

    totalClicksOnAdInMonths.sort((a, b) => a.month.localeCompare(b.month));

    return totalClicksOnAdInMonths;
}

export function calculateTotalClicksOnAdInYears(campaigns) {
    let clicksByYear = {};

    campaigns.forEach(campaign => {
        campaign.dataInYears.forEach(entry => {
            const year = entry.date_start; // Assuming this field exists and correctly represents the year
            const linkClickAction = entry.actions.find(action => action.action_type === "link_click");
            const linkClicks = linkClickAction ? parseInt(linkClickAction.value, 10) : 0;

            if (clicksByYear[year]) {
                clicksByYear[year] += linkClicks;
            } else {
                clicksByYear[year] = linkClicks;
            }
        });
    });

    let totalClicksOnAdInYears = Object.keys(clicksByYear).map(year => ({
        year: year,
        clicksOnAd: clicksByYear[year]
    }));

    totalClicksOnAdInYears.sort((a, b) => a.year.localeCompare(b.year));

    return totalClicksOnAdInYears;
}
