export default {
    storeFacebookAdAccounts(state, adAccounts) {
        state.user.marketingData.meta.adAccountsObject.adAccounts = adAccounts.data
    },
    storeSelectedAdAccount(state, adAccount) {
        state.user.marketingData.meta.adAccountsObject.selectedAdAccount = adAccount
    },

    storeAdAccountData(state, adAccountData) {
        state.user.marketingData.meta.selectedMarketingInsightData = adAccountData.data
    }
}