import { createStore } from 'vuex';

// State
import authState from './state/auth/authState';
import userState from './state/userState';
import subscriptionPlans from './state/subscriptionPlans';
import communicationState from './state/communication/communicationState';
import marketingTimelineState from './state/marketing/marketingTimelineState';
import consultantState from './state/consultant/consultantState';

//Actions
import authActions from './actions/auth/authActions';
import userActions from './actions/user/userActions';
import aiActions from './actions/ai/aiActions';
import webSocketActions from './actions/webSocketActions';
import facebookActions from './actions/marketing/meta/metaMarketingActions.js';
import fileActions from './actions/files/filesActions';
import filesProfileActions from './actions/files/filesProfileActions';
import calendarActions from './actions/calendar/calendarActions';
import userRelationsActions from './actions/user/userRelationsActions';
import marketingTimelineActions from './actions/marketing/marketingTimelineActions';
import paymentActions from './actions/payment/paymentActions';
import messagesActions from './actions/communication/messagesActions.js';

//Mutations
import authMutations from './mutations/user/authMutations.js';
import userMutations from './mutations/user/userMutations.js';
import generalMutations from './mutations/general/generalMutations.js';
import toastMutations from './mutations/toastMutations.js';
import webSocketMutations from './mutations/webSocketMutations.js';
import membershipMutations from './mutations/membership/membershipMutations.js';
import consultantMutations from './mutations/consultant/consultantMutations';
import facebookMutations from './mutations/marketing/meta/metaMarketingMutations.js';
import fileMutations from './mutations/files/filesMutations.js';
import calendarMutations from './mutations/calendar/calendarMutations.js';
import userRelationsMutations from './mutations/userRelationsMutations.js';
import communicationMutations from './mutations/communication/messagesMutations.js';
import marketingTimelineMutations from './mutations/marketing/marketingTimelineMutations';
import paymentMutations from './mutations/paymentMutations';

// Getters
import getters from './getters/getters.js';
import authGetters from './getters/auth/authGetters.js';
import userGetters from './getters/userGetters.js';
import marketingGetters from './getters/marketing/marketingGetters.js';
import communicationGetters from './getters/communication/messagesGetters.js';
import fileGetters from './getters/files/fileGetters.js';
import marketingTimelineGetters from './getters/marketingTimelineGetters.js';
import paymentGetters from './getters/paymentGetters.js';

const store = createStore({
    state: {
        token: null,
        toast: null,
        ...authState,
        ...userState,
        ...subscriptionPlans,
        ...communicationState,
        membership: {
            selectedMembership: null,
        },
        userUpdate: {
            message: "",
            status: null
        },
        files: {
            categorie_size_image: 0,
            categorie_size_documents: 0,
            categorie_size_videos: 0,
            files: [{}],
            filesSorted: [{}],
            folders: [{}],
            selectedFolder: "",
        },
        calendar: {
            marketingCalendar: {
                calendarDataArray: null,
            }
        },
        applyForPartnershipStatus: {
            status: null,
            message: ""
        },
        signupStatus: {
            status: null,
            message: ""
        },
        loginSucess: null,
        ...consultantState,
        ...marketingTimelineState,
    },
    mutations: {
        ...authMutations,
        ...userMutations,
        ...generalMutations,
        ...toastMutations,
        ...webSocketMutations,
        ...membershipMutations,
        ...consultantMutations,
        ...facebookMutations,
        ...fileMutations,
        ...calendarMutations,
        ...userRelationsMutations,
        ...communicationMutations,
        ...marketingTimelineMutations,
        ...paymentMutations,
    },
    actions: {
        ...authActions,
        ...webSocketActions,
        ...userActions,
        ...aiActions,
        ...facebookActions,
        ...fileActions,
        ...filesProfileActions,
        ...calendarActions,
        ...userRelationsActions,
        ...messagesActions,
        ...marketingTimelineActions,
        ...paymentActions,
    },
    getters: {
        ...getters,
        ...authGetters,
        ...userGetters,
        ...communicationGetters,
        ...fileGetters,
        ...marketingTimelineGetters,
        ...paymentGetters,
        ...marketingGetters,
    }
})

export default store;