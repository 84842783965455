import axios from '../../../../axios-auth'

const routePath = '/meta/instagram';

export default {
    async getDailyInsight({ commit, getters }) {
        const instagramAccountId = getters.getStoredInstagramId;
        
        return axios.get(
            `${routePath}/dailyInsight/${instagramAccountId}`, {
            params: {
                access_token: getters.getUserMetaCredentials.accessToken,
                metric: 'likes,profile_views,total_interactions',
                period: 'day',
                metric_type: 'total_value'
            }
        }).then((res) => {
            commit('storeInstagramDailyInsights', res.data);
        })
            .catch((error) => {
                console.log('action error', error.message);
                throw error;
            });
    },

    async getWeeklyInsight({ commit, getters }) {
        const instagramAccountId = getters.getStoredInstagramId;

        return axios.get(
            `${routePath}/weeklyInsight/${instagramAccountId}`, {
            params: {
                access_token: getters.getUserMetaCredentials.accessToken,
                metric: 'impressions,reach',
                period: 'week',
            }
        }).then((res) => {
            commit('storeInstagramWeeklyInsights', res.data);
        })
            .catch((error) => {
                console.log('action error', error.message);
                throw error;
            });
    },

    async fetchInstagramPosts({ commit, getters }) {
        const instagramAccountId = getters.getStoredInstagramId;

        return axios.get(
            `${routePath}/media/${instagramAccountId}`, {
            params: {
                access_token: getters.getUserMetaCredentials.accessToken,
            }
        }).then((res) => {
            commit('storeInstagramPosts', res.data);
        }).catch((error) => {
            throw error;
        });
    },

    async fetchInstagramPostInsight({ getters }, postId) {
        const instagramPostId = postId;

        return axios.get(
            `${routePath}/media/${instagramPostId}/insights`, {
            params: {
                access_token: getters.getUserMetaCredentials.accessToken,
                IG_MEDIA_ID: instagramPostId
            }
        }).then((res) => {
            return res.data;
        }).catch((error) => {
            console.log('fetchInstagramPosts action error', error.message);
            throw error;
        });
    }
}