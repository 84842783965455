// Service
import { MetaService } from "@/service/medias/MetaService";

// Initialize service
const metaService = new MetaService();

export default {
    user(state) {
        return state.user
    },
    getUserEmail(state) {
        return state.user.email
    },

    getUserMetaCredentials(state) {
        return state.user.platformCredentials.meta
    },

    getStoredInstagramId() {
        const selectedPage = metaService.getMetaPageSelection();
        // Return Instagram ID if it exists, otherwise return null or undefined
        return selectedPage?.instagram?.id || null;
    }
}