<script setup>
</script>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style scoped></style>
