export function determineCampaignObjective(objective) {
    const objectiveMapping = {
        APP_INSTALLS: "App Installationer",
        BRAND_AWARENESS: "Brand Bevidsthed",
        CONVERSIONS: "Konverteringer",
        EVENT_RESPONSES: "Event Respons",
        LEAD_GENERATION: "Lead Generering",
        LINK_CLICKS: "Trafik",
        LOCAL_AWARENESS: "Lokal Bevidsthed",
        MESSAGES: "Beskeder",
        OFFER_CLAIMS: "Tilbudskrav",
        OUTCOME_APP_PROMOTION: "App Promotion Resultat",
        OUTCOME_AWARENESS: "Bevidsthedsresultat",
        OUTCOME_ENGAGEMENT: "Engagement Resultat",
        OUTCOME_LEADS: "Leads",
        OUTCOME_SALES: "Salgsresultat",
        OUTCOME_TRAFFIC: "Trafik",
        PAGE_LIKES: "Side Likes",
        POST_ENGAGEMENT: "Indlægsengagement",
        PRODUCT_CATALOG_SALES: "Produktkatalogsalg",
        REACH: "Rækkevidde",
        STORE_VISITS: "Butiksbesøg",
        VIDEO_VIEWS: "Video Visninger"
    };

    return objectiveMapping[objective] || "Ukendt type";

}