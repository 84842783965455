export default {
    initializeWebSocket({ state, commit }) {
        const ws = new WebSocket(`${import.meta.env.VITE_WEBSOCKET_URL}?token=${localStorage.getItem('token')}`);

        ws.addEventListener('open', () => {
            console.log('WebSocket Connected');
            commit('SET_WS', ws);

            // Send a pong back to the server when you receive a ping
            ws.addEventListener('ping', () => {
                ws.pong('keepalive');
            });

            // Periodically send a ping message
            setInterval(() => {
                if (ws.readyState === WebSocket.OPEN) {
                    ws.send(`ping from ${state.user.uuid}`);
                }
            }, 30000);
        });

        ws.onmessage = function (event) {
            const notification = JSON.parse(event.data);

            if (notification.type === 'new_message') {
                commit('storeReceivedMessage', notification.originalMessage)

                if (state.toast) { // Check if the toast instance is available
                    state.toast.add({
                        severity: 'info',
                        summary: notification.data.title,
                        detail: notification.data.message,
                        group: 'block3',
                        life: 5000
                    });
                } else {
                    console.error('Toast instance is not initialized.');
                }
            }
        };

        ws.addEventListener('message', (event) => {
            const data = JSON.parse(event.data);
            commit('SET_WS_DATA', data);
        });

        ws.addEventListener('close', () => {
            console.log('WebSocket closed');
            commit('SET_WS', null);
        });
    },
};
