// Utility
import { determineCampaignObjective } from '@/utils/marketing/meta/data/campaigns/objectiveProcessor';
import {
    calculateTotalSpend,
    calculatePriorTotalSpend,
    calculateTotalSpendByDate,
    calculateTotalSpendInWeeks,
    calculateTotalSpendInMonths,
    calculateTotalSpendInYears
} from '@/utils/marketing/meta/data/campaigns/spendProcessor';

import {
    calculateTotalClicksOnAd,
    calculatePriorTotalClicksOnAd,
    calculateTotalClicksOnAdTrendPercentage,
    calculateTotalClicksOnAdByDate,
    calculateTotalClicksOnAdInWeeks,
    calculateTotalClicksOnAdInMonths,
    calculateTotalClicksOnAdInYears
} from '@/utils/marketing/meta/data/campaigns/linkClickProcessor';

import {
    calculateTotalAverageCPC,
    calculatePriorAverageCPC,
    calculateTotalAverageCPCTrendPercentage,
    calculateTotalAverageCPCInDays,
    calculateTotalAverageCPCInWeeks,
    calculateTotalAverageCPCInMonths,
    calculateTotalAverageCPCInYears
} from '@/utils/marketing/meta/data/campaigns/cpcProcessor';

import { calculateTotalImpressions, calculatePriorTotalImpressions, calculateTotalImpressionsTrendPercentage, calculateTotalImpressionsByDate } from '@/utils/marketing/meta/data/campaigns/impressionsProcessor';
import { calculateTotalReach, calculatePriorTotalReach, calculateTotalReachTrendPercentage, calculateTotalReachByDate } from '@/utils/marketing/meta/data/campaigns/reachProcessor';
import { calculateTotalAverageFrequency, calculatePriorAverageFrequency, calculateTotalAverageFrequencyTrendPercentage, calculateTotalAverageFrequencyArray } from '@/utils/marketing/meta/data/campaigns/frequencyProcessor';
import { calculateTotalPageEngagement, calculatePriorTotalPageEngagement, calculateTotalPageEngagementTrendPercentage, calculateTotalPageEngagementArray } from '@/utils/marketing/meta/data/campaigns/pageEngagementProcessor';
import { calculateTotalPostReactions, calculatePriorTotalPostReactions, calculateTotalPostReactionsTrendPercentage, calculateTotalPostReactionsArray } from '@/utils/marketing/meta/data/campaigns/postReactionsProcessor';

export default {
    storeFacebookAdCampaigns(state, campaigns) {
        state.user.marketingData.meta.adCampaigns.adCampaigns = campaigns.data
    },

    storeSelectedAdCampaign(state, campaign) {
        state.user.marketingData.meta.selectedAdCampaign = campaign
    },

    addCampaignToAdCampaignsObjectDetailed(state, campaign) {
        const selectedTimePeriod = JSON.parse(localStorage.getItem('selectedTimePeriod'));

        let existingCampaign = state.user.marketingData.meta.adCampaigns.adCampaignsDetailed.find(element => element.name === campaign.name);

        if (!existingCampaign) {

            let detailedCampaign = {...campaign};

            const matchingCampaign = state.user.marketingData.meta.adCampaigns.adCampaigns.find(adCampaign => adCampaign.id === detailedCampaign.id);

            const objective = determineCampaignObjective(matchingCampaign.objective)
            const totalSpend = calculateTotalSpend([campaign], selectedTimePeriod);
            const totalImpressions = calculateTotalImpressions([campaign], selectedTimePeriod);
            const totalClicksOnAd = calculateTotalClicksOnAd([campaign], selectedTimePeriod);
            detailedCampaign.objective = objective;
            detailedCampaign.totalSpend = totalSpend;
            detailedCampaign.totalImpressions = totalImpressions;
            detailedCampaign.totalClicksOnAd = totalClicksOnAd;

            state.user.marketingData.meta.adCampaigns.adCampaignsDetailed.push(detailedCampaign);
        }
    },

    resetAdCampaignsObjectDetailed(state) {
        state.user.marketingData.meta.adCampaigns.adCampaignsDetailed = [];
    },

    calculateAndStoreTotalSpend(state, campaigns) {
        const selectedTimePeriod = JSON.parse(localStorage.getItem('selectedTimePeriod'));

        state.user.marketingData.meta.adCampaigns.generalMetrics.spend.totalSpend = calculateTotalSpend(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.adCampaigns.generalMetrics.spend.priorTotalSpend = calculatePriorTotalSpend(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.adCampaigns.generalMetrics.spend.totalSpendArray = calculateTotalSpendByDate(campaigns);
        state.user.marketingData.meta.adCampaigns.generalMetrics.spend.totalSpendInWeeks = calculateTotalSpendInWeeks(campaigns);
        state.user.marketingData.meta.adCampaigns.generalMetrics.spend.totalSpendInMonths = calculateTotalSpendInMonths(campaigns);
        state.user.marketingData.meta.adCampaigns.generalMetrics.spend.totalSpendInYears = calculateTotalSpendInYears(campaigns);
    },

    updateAdCampaignClickMetrics(state, campaigns) {
        const selectedTimePeriod = JSON.parse(localStorage.getItem('selectedTimePeriod'));

        state.user.marketingData.meta.adCampaigns.generalMetrics.clicksOnAd.totalClicksOnAd = calculateTotalClicksOnAd(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.adCampaigns.generalMetrics.clicksOnAd.priorTotalClicksOnAd = calculatePriorTotalClicksOnAd(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.adCampaigns.generalMetrics.clicksOnAd.totalClicksOnAdTrendPercentage = calculateTotalClicksOnAdTrendPercentage(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.adCampaigns.generalMetrics.clicksOnAd.totalClicksOnAdDays = calculateTotalClicksOnAdByDate(campaigns);
        state.user.marketingData.meta.adCampaigns.generalMetrics.clicksOnAd.totalClicksOnAdInWeeks = calculateTotalClicksOnAdInWeeks(campaigns);
        state.user.marketingData.meta.adCampaigns.generalMetrics.clicksOnAd.totalClicksOnAdInMonths = calculateTotalClicksOnAdInMonths(campaigns);
        state.user.marketingData.meta.adCampaigns.generalMetrics.clicksOnAd.totalClicksOnAdInYears = calculateTotalClicksOnAdInYears(campaigns);
    },

    updateAdCampaignCPCMetrics(state, campaigns) {
        const selectedTimePeriod = JSON.parse(localStorage.getItem('selectedTimePeriod'));

        state.user.marketingData.meta.adCampaigns.generalMetrics.cost_per_unique_click.totalAverageCPC = calculateTotalAverageCPC(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.adCampaigns.generalMetrics.cost_per_unique_click.priorAverageCPC = calculatePriorAverageCPC(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.adCampaigns.generalMetrics.cost_per_unique_click.totalAverageCPCTrendPercentage = calculateTotalAverageCPCTrendPercentage(campaigns, selectedTimePeriod);
        state.user.marketingData.meta.adCampaigns.generalMetrics.cost_per_unique_click.totalDataInDays = calculateTotalAverageCPCInDays(campaigns);
        state.user.marketingData.meta.adCampaigns.generalMetrics.cost_per_unique_click.totalDataInWeeks = calculateTotalAverageCPCInWeeks(campaigns);
        state.user.marketingData.meta.adCampaigns.generalMetrics.cost_per_unique_click.totalDataInMonths = calculateTotalAverageCPCInMonths(campaigns);
        state.user.marketingData.meta.adCampaigns.generalMetrics.cost_per_unique_click.totalDataInYears = calculateTotalAverageCPCInYears(campaigns);
    },


    // TODO: Implement in the future
    // calculateAndStoreTotalValues(state, campaigns) {
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.impressions.totalImpressions = calculateTotalImpressions(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.impressions.priorTotalImpressions = calculatePriorTotalImpressions(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.impressions.totalImpressionsTrendPercentage = calculateTotalImpressionsTrendPercentage(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.impressions.totalImpressionsArray = calculateTotalImpressionsByDate(campaigns);

    //     state.user.marketingData.meta.adCampaigns.generalMetrics.reach.totalReach = calculateTotalReach(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.reach.priorTotalReach = calculatePriorTotalReach(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.reach.totalReachTrendPercentage = calculateTotalReachTrendPercentage(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.reach.totalReachArray = calculateTotalReachByDate(campaigns);

    //     state.user.marketingData.meta.adCampaigns.generalMetrics.averageFrequency.totalAverageFrequency = calculateTotalAverageFrequency(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.reach.priorTotalAverageFrequency = calculatePriorAverageFrequency(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.averageFrequency.totalAverageFrequencyTrendPercentage = calculateTotalAverageFrequencyTrendPercentage(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.averageFrequency.totalAverageFrequencyArray = calculateTotalAverageFrequencyArray(campaigns);

    //     state.user.marketingData.meta.adCampaigns.generalMetrics.pageEngagement.totalPageEngagement = calculateTotalPageEngagement(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.pageEngagement.priorTotalPageEngagement = calculatePriorTotalPageEngagement(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.pageEngagement.totalPageEngagementTrendPercentage = calculateTotalPageEngagementTrendPercentage(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.pageEngagement.totalPageEngagementArray = calculateTotalPageEngagementArray(campaigns);

    //     state.user.marketingData.meta.adCampaigns.generalMetrics.postReactions.totalPostReactions = calculateTotalPostReactions(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.postReactions.priorTotalPostReactions = calculatePriorTotalPostReactions(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.postReactions.totalPostReactionsTrendPercentage = calculateTotalPostReactionsTrendPercentage(campaigns);
    //     state.user.marketingData.meta.adCampaigns.generalMetrics.postReactions.totalPostReactionsArray = calculateTotalPostReactionsArray(campaigns);
    // },
}