import axios from '../../axios-auth'

export default {
    applyForPartnership({ commit, state }, userData) {
        axios.put(`/user/appypartnership/${userData.email}`, userData).then(res => {
            commit('applyForPartnershipStatus', {
                status: 200,
                message: res.data.message,
            })
            commit('addBusinessPartnerRequest', userData.businessPartnerEmail)
        }).catch(error => {
            commit('applyForPartnershipStatus', {
                status: error.response.status,
                message: error.response.statusText,
            })
        })
    },
    applyForPartnershipStatusReset({ commit }) {
        commit('applyForPartnershipStatusReset')
    },

    removePartnershipRequest({ commit, state }, businessPartnerResponse) {
        axios.post(`/user/removePartnershipRequest`, businessPartnerResponse)
            .then(res => {
                if (state.user.userType === 'business') {
                    commit('removeBusinessPartnerRequest', businessPartnerResponse.consultantEmail)
                } else {
                    commit('removeBusinessPartnerRequest', businessPartnerResponse.businessEmail)
                }
            }).catch(error => {
                console.log(error);
            })
    },

    acceptPartnershipRequest({ commit, state, dispatch }, businessPartnerResponse) {
        axios.post(`/user/acceptPartnership`, businessPartnerResponse)
            .then(res => {
                if (state.user.userType === 'business') {
                    commit('addBusinessPartner', res.data.business.business_partners[0])
                } else {
                    commit('addBusinessPartner', res.data.consultant.business_partners[0])
                }
                dispatch('removePartnershipRequest', businessPartnerResponse);
            }).catch(error => {
                console.log(error);
            })
    },

    removePartnership({ commit, state }, businessPartnerResponse) {
        axios.post(`/user/removePartnership`, businessPartnerResponse).then(res => {
            if (state.user.userType === 'business') {
                commit('removeBusinessPartner', res.data.consultant.email)
            } else {
                localStorage.removeItem('businessDataOnBehalfOf_UUID')
                localStorage.removeItem('businessDataOnBehalfOf_Name')
                localStorage.removeItem('businessDataOnBehalfOf_Email')
                commit('removeBusinessPartner', res.data.business.email)
            }
        }).catch(error => {
            console.log(error);
        })
    }
}
