// Helper function to calculate trend percentage and determine color
function calculateTrendResult(trendPercentage) {
    return {
        value: `${trendPercentage > 0 ? '+' : ''}${trendPercentage.toFixed(2)}%`,
        color: trendPercentage > 0 ? 'text-green-500' : 'text-red-500'
    };
}

export function calculateTotalImpressions(campaigns, selectedTimePeriod) {
    let totalImpressions = 0;

    campaigns.forEach(campaign => {
        const { data } = campaign;
        let daysToSum = selectedTimePeriod.sumDays;

        if (selectedTimePeriod.value === 'maximum') {
            daysToSum = data.length;
        }

        const relevantData = data.slice(-daysToSum);
        relevantData.forEach(entry => {
            totalImpressions += parseInt(entry.impressions, 10);
        });
    });

    return totalImpressions;
}

export function calculatePriorTotalImpressions(campaigns) {
    let priorTotalImpressions = 0;

    campaigns.forEach(campaign => {
        const { data } = campaign;
        let daysToSum = 0;
        let daysToSkip = 0;

        if (data.length === 7) {
            daysToSum = 1; // Prior day only
            daysToSkip = 1;
        } else if (data.length === 14) {
            daysToSum = 7; // Prior 7 days
            daysToSkip = 7;
        } else if (data.length === 56) {
            daysToSum = 28; // Prior 28 days
            daysToSkip = 28;
        }

        // Skip the most recent period and then take the daysToSum for calculating prior total impressions
        const relevantData = data.slice(-daysToSum - daysToSkip, -daysToSkip);
        relevantData.forEach(entry => {
            if (entry.impressions !== undefined) {
                priorTotalImpressions += parseInt(entry.impressions, 10);
            }
        });
    });

    return priorTotalImpressions;
}

export function calculateTotalImpressionsTrendPercentage(campaigns, selectedTimePeriod) {
    let startPeriodTotalImpressions = 0;
    let endPeriodTotalImpressions = 0;

    campaigns.forEach(campaign => {
        const { data } = campaign;
        let periodLength = 0;

        if (data.length === 7) {
            periodLength = 1;
        } else if (data.length === 14) {
            periodLength = 7;
        } else if (data.length === 56) {
            periodLength = 28;
        }

        const startPeriodData = data.slice(-(2 * periodLength), -periodLength);
        const endPeriodData = data.slice(-periodLength);

        startPeriodData.forEach(entry => {
            startPeriodTotalImpressions += parseInt(entry.impressions, 10);
        });

        endPeriodData.forEach(entry => {
            endPeriodTotalImpressions += parseInt(entry.impressions, 10);
        });
    });

    const trendPercentage = startPeriodTotalImpressions !== 0 ? ((endPeriodTotalImpressions - startPeriodTotalImpressions) / startPeriodTotalImpressions) * 100 : 0;

    // Utilize the calculateTrendResult function for consistency
    return calculateTrendResult(trendPercentage);
}

export function calculateTotalImpressionsByDate(campaigns) {
    // Object to hold the aggregated impressions amounts by date
    let impressionsByDate = {};

    campaigns.forEach(campaign => {
        const { data } = campaign;

        data.forEach(entry => {
            // Check if the date already exists in the aggregation object
            if (impressionsByDate[entry.date_start]) {
                // If it does, add the current impressions to the existing amount
                impressionsByDate[entry.date_start] += parseInt(entry.impressions, 10);
            } else {
                // If it doesn't, initialize the date with the current impressions
                impressionsByDate[entry.date_start] = parseInt(entry.impressions, 10);
            }
        });
    });

    // Transform the aggregation object into an array of objects
    let totalImpressionsArray = Object.keys(impressionsByDate).map(date => {
        return { date: date, impressions: impressionsByDate[date] };
    });

    totalImpressionsArray.sort((a, b) => new Date(a.date) - new Date(b.date));

    return totalImpressionsArray;
}
