export default {
    marketingData: {
        meta: {
            adAccountsObject: {
                adAccounts: [],
                selectedAdAccount: null,
            },
            adCampaigns: {
                generalMetrics: {
                    spend: {
                        totalSpend: null,
                        priorTotalSpend: null,
                        totalSpendArray: [],
                        totalSpendInWeeks: [],
                        totalSpendInMonths: [],
                        totalSpendInYears: [],
                    },
                    cost_per_unique_click: {
                        totalAverageCPC: null,
                        priorAverageCPC: null,
                        totalAverageCPCTrendPercentage: {
                            value: null,
                            color: null,
                        },
                        totalDataInDays: [],
                        totalDataInWeeks: [],
                        totalDataInMonths: [],
                        totalDataInYears: [],
                    },
                    impressions: {
                        totalImpressions: null,
                        priorTotalImpressions: null,
                        totalImpressionsTrendPercentage: null,
                        totalImpressionsArray: [],
                    },
                    clicksOnAd: {
                        totalClicksOnAd: null,
                        priorTotalClicksOnAd: null,
                        totalClicksOnAdTrendPercentage: null,
                        totalClicksOnAdDays: [],
                        totalClicksOnAdInWeeks: [],
                        totalClicksOnAdInMonths: [],
                        totalClicksOnAdInYears: [],
                    },
                    reach: {
                        totalReach: null,
                        priorTotalReach: null,
                        totalReachTrendPercentage: null,
                        totalReachArray: [],
                    },
                    averageFrequency: {
                        totalAverageFrequency: null,
                        priorTotalAverageFrequency: null,
                        totalAverageFrequencyTrendPercentage: null,
                        totalAverageFrequencyArray: [],
                    },
                    pageEngagement: {
                        totalPageEngagement: null,
                        priorTotalPageEngagement: null,
                        totalPageEngagementTrendPercentage: null,
                        totalPageEngagementArray: [],
                    },
                    postReactions: {
                        totalPostReactions: null,
                        priorTotalPostReactions: null,
                        totalPostReactionsTrendPercentage: null,
                        totalPostReactionsArray: [],
                    },
                },
                adCampaigns: [],
                adCampaignsDetailed: [],
            },
            selectedMarketingInsightData: [],

            instagram: {
                posts: [],
                dailyInsights: [],
                weeklyInsights: [],
                monthlyInsights: [],
            }
        },
        google: {

        }
    }
}