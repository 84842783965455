export default {
    instagramDailyInsights: (state) => {
        return state.user.marketingData.meta.instagram.dailyInsights;
    },
    instagramWeeklyInsights: (state) => {
        return state.user.marketingData.meta.instagram.weeklyInsights;
    },
    instagramPosts: (state) => {
        return state.user.marketingData.meta.instagram.posts;
    }
}
