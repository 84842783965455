import axios from '../../axios-auth'

// Storage route path
const routePath = '/storage/profile';

export default {
    getProfileLogo({ dispatch, commit }, email) {
        axios.get(`${routePath}/folder/${email}`)
            .then((res) => {
                if (res.data.files && res.data.files.length > 0) {
                    const logo = {
                        name: res.data.files[0].name,
                        path: res.data.files[0].fullName,
                    };
                    commit('storeProfileLogo', logo);
                    dispatch('getProfileLogoUrl', logo.path);
                } else {
                    console.log('No files found for this email.');
                    return null;
                }
            })
            .catch((error) => {
                console.log('action error', error);
            });
    },

    async getProfileLogoUrl({ commit }, fileName) {
        await axios.get(`${routePath}/fileUrl?fileName=${fileName}`)
            .then((res) => {
                const logo = {
                    url: res.data.url,
                }
                commit('storeProfileLogo', logo)
            })
            .catch((error) => {
                console.log('ERROR: getProfileLogoUrl action: ', error);
            });
    },

    uploadProfileLogo({ commit }, formData) {
        axios.post(`${routePath}/file`, formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((res) => {
                const logo = {
                    url: res.data.data.url,
                    path: res.data.data.path,
                }
                console.log("uploadProfileLogo", logo)
                commit('storeProfileLogo', logo)
            })
            .catch((error) => {
                console.log('action error', error);
            });
    },

    uploadProfileImage({ commit }, formData) {
        axios.post(`${routePath}/profile_image`, formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((res) => {
                const image = {
                    url: res.data.data.url,
                    path: res.data.data.path,
                }
                commit('storeProfileImage', image)
            })
            .catch((error) => {
                console.log('action error', error);
            });
    },

    deleteProfileLogo({ state, commit }) {
        if (state.user.profile.logo.path) {
            axios.delete(`${routePath}/file?fileName=${state.user.profile.logo.path}`)
                .then(() => {
                    commit('removeProfileLogo')
                })
                .catch((error) => {
                    console.log('action error', error);
                });
        }
    },
}