import axios from '../../../axios-auth'

const routePath = '/meta';

export default {
    initMetaUser({ commit, state }, userData) {
        console.log('initMetaUser', userData);
        return axios.post(`${routePath}/initUser/${state.user.uuid}`, userData)
            .then((res) => {
                console.log('initMetaUser action res', res);
                commit('setMetaAuthenticated', true);
                commit('storeUser', res.data.data)
                return res;
            })
            .catch((error) => {
                console.log('action error', error);
                throw error;
            });
    },
}